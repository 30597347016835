export const brands = [
  'Acer',
  'Airis',
  'AK Telecom',
  'Alcatel',
  'Amoi',
  'AnexTek',
  'AnyDATA',
  'Apple',
  'Archos',
  'Arcoa',
  'Ares',
  'Arima',
  'ASUS',
  'Audiovox',
  'Axia',
  'BB-mobile',
  'BBK_Electronics',
  'Belling',
  'BELLPERRE',
  'Bellwave',
  'Benefon',
  'BenQ',
  'BenQ-Siemens',
  'Binatone',
  'Bird',
  'BlackBerry',
  'Bosch',
  'BQ',
  'BRAVIS',
  'Caterpillar',
  'CEC',
  'Cellvic',
  'CHEA',
  'Conquest',
  'COSUN',
  'CUBOT',
  'Curitel',
  'CyberBell',
  'Daewoo',
  'Danger',
  'DBTel',
  'DELL',
  'DEXP',
  'Digma',
  'Dnet',
  'Dolphin',
  'Dopod',
  'Dowtel',
  'Drin.it',
  'E28',
  'Elitek',
  'EMBLAZE',
  'EMGETON',
  'Emol',
  'Enteos',
  'Ericsson',
  'Eten',
  'Etuline',
  'Europhone',
  'EVO',
  'EXPLAY',
  'Ezio',
  'Fly',
  'G-Plus',
  'Garmin',
  'Geo',
  'Giga',
  'Gigabyte',
  'Ginzzu',
  'Gresso',
  'GSL',
  'GTran',
  'Haier',
  'Handspring',
  'Highscreen',
  'Hisense',
  'Hitachi',
  'HP',
  'HTC',
  'Huawei',
  'Hyundai',
  'Innostream',
  'INTEX',
  'IQM',
  'Kejian',
  'KENEKSI',
  'Kenned',
  'Krome',
  'Kyocera',
  'LeEco (LeTV)',
  'Lenovo',
  'LEXAND',
  'LG',
  'Maxon',
  'Maxvi',
  'Meizu',
  'Micromax',
  'Microsoft',
  'Mitac',
  'Mitsubishi',
  'Mobiado',
  'Mobile shot',
  'MODOTTEL',
  'Motorola',
  'NEC',
  'Neonode',
  'Newgen',
  'Nokia',
  'O2',
  'Okwap',
  'Onda',
  'OnePlus',
  'Oppo',
  'Palm',
  'Panasonic',
  'Pantech',
  'Philips',
  'Phoenix',
  'Prestigio',
  'Pretec',
  'QTek',
  'Qualcomm',
  'ROAD',
  'Rolsen',
  'RoverPC',
  'RugGear',
  'RWT',
  'Sagem',
  'Samsung',
  'Sanyo',
  'Secufone',
  'SED',
  'Sendo',
  'Senseit',
  'Sewon',
  'Sharp',
  'Siemens',
  'Sitronics',
  'SKY',
  'SkyVox',
  'Sonim',
  'Sony',
  'SonyEricsson',
  'Soutec',
  'Synertek',
  'T-Mobile',
  'Tag Heuer',
  'TCL',
  'Tel.Me',
  'Telit',
  'Telson',
  'TeXet',
  'Thuraya',
  'Toplux',
  'Toshiba',
  'Treo',
  'TSS',
  'Versace',
  'VERTEX',
  'Vertu',
  'ViewSonic',
  'VK_Mobile',
  'Vodafone',
  'Voxtel',
  'X-cute',
  'Xiaomi',
  'Xplore',
  'XTE',
  'Yakumo',
  'Yota',
  'Zakang',
  'Zapp',
  'Zetta',
  'Zopo',
  'ZTE',
  'Honor',
  'Techno',
  'Vivo',
  'Realme',
  'MSI',
  'Test',
  'Tet',
  'null',
  'TECNO',
];
