import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {usersApi} from '../../api/users';

const EditUser = ({
  handleClose,
  open,
  isDeleting,
  setIsDeleting,
  editUser,
  setEditUser,
  setRefresh,
  refresh,
}) => {
  const {t} = useTranslation();

  const handleEditUser = (e) => {
    e.preventDefault();

    usersApi
      .editUser({
        name: editUser.name,
        email: editUser.email,
        password: editUser.password,
        id: editUser.id,
      })
      .then((resp) => {
        console.log(resp);
        setRefresh(!refresh);
      });
    handleClose(true);
  };

  const handleDeleteUser = (e) => {
    e.preventDefault();

    usersApi.deleteUser(editUser.id).then((resp) => {
      console.log(resp);
      setRefresh(!refresh);
    });
    handleClose(true);
    setIsDeleting(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        {open && isDeleting ? (
          <>
            <DialogTitle id="alert-dialog-title">
              {'Удалить менеджера?'}
            </DialogTitle>

            <DialogActions>
              <Button onClick={handleClose}>Отмена</Button>
              <Button onClick={handleDeleteUser} autoFocus>
                Удалить
              </Button>
            </DialogActions>
          </>
        ) : (
          <form onSubmit={handleEditUser}>
            <DialogTitle>Редактировать менеджера</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label={t('name')}
                value={editUser?.name}
                fullWidth
                variant="standard"
                onChange={(e) =>
                  setEditUser((prev) => ({...prev, name: e.target.value}))
                }
              />
              <TextField
                autoFocus
                margin="dense"
                value={editUser?.email}
                id="name"
                label={t('email')}
                type="email"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  setEditUser((prev) => ({...prev, email: e.target.value}))
                }
              />
              <TextField
                autoFocus
                margin="dense"
                value={editUser?.password || ''}
                id="name"
                label={t('password')}
                fullWidth
                variant="standard"
                onChange={(e) =>
                  setEditUser((prev) => ({...prev, password: e.target.value}))
                }
              />
              <FormControl fullWidth sx={{mt: 2}}>
                <InputLabel id="demo-simple-select-label">
                  {t('Role')}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={editUser?.role || 'user'}
                  label={t('role')}
                  onChange={(e) =>
                    setEditUser((prev) => ({...prev, role: e.target.value}))
                  }
                >
                  <MenuItem value={'admin'}>ADMIN</MenuItem>
                  <MenuItem value={'user'}>MANAGER</MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Отмена</Button>
              <Button type="submit" variant="contained">
                Сохранить
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </>
  );
};

export default EditUser;
