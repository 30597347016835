import {Button, ButtonGroup} from '@mui/material';
import * as React from 'react';
import useGlobal from '../store/slice';
import {useTranslation} from 'react-i18next';

const LanguageToggle = () => {
  const {lang, setLang} = useGlobal();

  const {i18n} = useTranslation();
  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
  };

  const setRu = () => {
    setLang('ru');
    changeLang('ru');
  };

  const setUz = () => {
    changeLang('uz');
    setLang('uz');
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
        sx={{ml: 2}}
      >
        <Button
          sx={{
            backgroundColor: lang === 'ru' ? '1D1D1B' : '#FFF',
            color: lang === 'ru' ? ' #FFF' : '#1D1D1B',
            '&:hover': {color: '#FFF'},
          }}
          onClick={setRu}
        >
          ru
        </Button>
        <Button
          onClick={setUz}
          sx={{
            backgroundColor: lang === 'uz' ? '#1D1D1B' : '#FFF',
            color: lang === 'uz' ? '#FFF' : '#1D1D1B',
            '&:hover': {color: '#FFF'},
          }}
        >
          uz
        </Button>
      </ButtonGroup>
    </>
  );
};

export default LanguageToggle;
