import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import './i18n';
import {AuthProvider} from './context/AuthContext';
import {ThemeProvider} from '@emotion/react';
import {themeUI} from './assets/theme';
import './assets/font/stylesheet.css';
import './assets/styles.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CssBaseline>
      <ThemeProvider theme={themeUI}>
        <AuthProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    </CssBaseline>
  </React.StrictMode>
);
