import {Box, Toolbar, Typography} from '@mui/material';
import React from 'react';

import Logo from '../../assets/Logo-F.png';
import useGlobal from '../../store/slice';

const Footer = () => {
  const {user} = useGlobal();
  const date = new Date();
  return (
    <React.Fragment>
      <Toolbar
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: '#303030',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <img src={Logo} alt="" height={30} width={100} />
          <Box>
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '16px ',
                color: '#F5F6F9',
              }}
            >
              {user.user_name}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '16px ',
              color: '#F5F6F9',
            }}
          >
            {date.toLocaleDateString()}
          </Typography>
        </Box>
      </Toolbar>
    </React.Fragment>
  );
};

export default Footer;
