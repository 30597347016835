import * as React from 'react';
import {Grid, Container} from '@mui/material';
import Header from './Header';
import {useTranslation} from 'react-i18next';

export default function Home() {
  const {t} = useTranslation();
  return (
    <Container maxWidth="xl">
      <Header />
      <main>
        <Grid container spacing={1} sx={{mt: 3}}>
          <h1>{t('text')}</h1>
        </Grid>
      </main>
    </Container>
  );
}
