import * as React from 'react';
import {
  Typography,
  Button,
  Paper,
  Toolbar,
  Container,
  Box,
  AppBar,
  CssBaseline,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

import {useNavigate} from 'react-router-dom';
import useGlobal from '../../store/slice';
import {useTranslation} from 'react-i18next';
import {usersApi} from '../../api/users';

export default function UserCreate() {
  const {t} = useTranslation();
  const {user} = useGlobal();
  const navigate = useNavigate();
  const [managerData, setManagerData] = React.useState({role: 'user'});

  const handleHome = () => {
    navigate('/');
  };

  const handleCreateUser = (e) => {
    e.preventDefault();
    console.log(managerData);
    usersApi.createUser(managerData).then((data) => {
      console.log('created user', managerData);
      navigate('/management');
    });
  };

  React.useEffect(() => {
    if (!user?.user_role === 'admin') {
      navigate('/auth');
    }
  }, [user]);

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h5" color="inherit" noWrap>
            {t('Создание менеджера')}
          </Typography>
          <Button variant="contained" onClick={handleHome}>
            {t('back_tomain')}
          </Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="md" sx={{mb: 4}}>
        <Paper variant="outlined" sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}>
          <Typography component="h1" variant="h4" align="center">
            {t('Создать менеджера')}
          </Typography>
          <form onSubmit={handleCreateUser}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="name"
              label={t('name')}
              // helperText={helperTextLogin}
              // error={errorLogin}
              onChange={(e) =>
                setManagerData((prev) => ({...prev, name: e.target.value}))
              }
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="email"
              label={t('email')}
              type="email"
              // helperText={helperTextLogin}
              // error={errorLogin}
              onChange={(e) =>
                setManagerData((prev) => ({...prev, email: e.target.value}))
              }
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('password')}
              // helperText={helperTextLogin}
              // error={errorLogin}
              onChange={(e) =>
                setManagerData((prev) => ({...prev, password: e.target.value}))
              }
            />
            <FormControl fullWidth sx={{mt: 2}}>
              <InputLabel id="demo-simple-select-label">{t('Role')}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={managerData?.role || 'user'}
                label={t('role')}
                onChange={(e) =>
                  setManagerData((prev) => ({...prev, role: e.target.value}))
                }
              >
                <MenuItem value={'admin'}>ADMIN</MenuItem>
                <MenuItem value={'user'}>MANAGER</MenuItem>
              </Select>
            </FormControl>

            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
              <Button variant="contained" sx={{mt: 3, ml: 1}} type="submit">
                Save
              </Button>
            </Box>
          </form>
        </Paper>
      </Container>
    </React.Fragment>
  );
}
