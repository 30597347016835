import axios from 'axios';

const APIHOST = 'https://dev.itconsult.uz/api/v1';

function getLocalToken() {
  const token = window.localStorage.getItem('accessToken');
  return `Bearer ${token}`;
}

class PoliceApi {
  //   getAllUsers() {
  //     return axios.get(`${APIHOST}/users`, {
  //       headers: {
  //         Authorization: getLocalToken(),
  //       },
  //     });
  //   }

  createPolice(data) {
    return axios.post(
      `${APIHOST}/policies`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: getLocalToken(),
        },
      }
    );
  }
}

export const policeApi = new PoliceApi();
