import {createTheme} from '@mui/material/styles';

export const themeUI = createTheme({
  palette: {
    primary: {
      main: '#1D2026',
    },
    secondary: {
      main: '#2622FF',
    },
    // error: {
    //   // main: green[500],
    //   //   light:'',
    //   //   dark:'',
    // },
  },
  typography: {
    fontFamily: " 'Okta Neue', sans-serif",
    fontSize: 16,
  },
});
