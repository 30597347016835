import * as React from 'react';
import {
  Typography,
  Button,
  Paper,
  Toolbar,
  Container,
  AppBar,
  CssBaseline,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Logo from '../../assets/Logo.png';

import {useNavigate} from 'react-router-dom';
import useGlobal from '../../store/slice';
import {useTranslation} from 'react-i18next';
import {usersApi} from '../../api/users';
import EditUser from './EditUser';

export default function Management() {
  const {t} = useTranslation();
  const {user} = useGlobal();
  const navigate = useNavigate();
  const [usersList, setUsersList] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [editUser, setEditUser] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);

  const handleHome = () => {
    navigate('/');
  };

  React.useEffect(() => {
    usersApi.getAllUsers().then((users) => {
      setUsersList(users.data.results);
    });
  }, [refresh]);

  React.useEffect(() => {
    if (!user?.user_role === 'admin') {
      navigate('/auth');
    }
  }, [user]);

  const handleClickOpen = (row, setdelete) => {
    setEditUser(row);
    if (setdelete) {
      setIsDeleting(true);
    }
    setOpen(true);
  };

  const handleClose = async () => {
    setOpen(false);
    setIsDeleting(false);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Box>
            <img src={Logo} alt="" height={50} />
          </Box>
          <Typography variant="h4" color="inherit" noWrap sx={{ml: 2}}>
            {t('Менеджмент')}
          </Typography>

          <Button variant="contained" color="secondary" onClick={handleHome}>
            {t('back_tomain')}
          </Button>
        </Toolbar>
      </AppBar>

      <Container component="main" maxWidth="lg" sx={{mb: 2, mt: 4}}>
        <Button
          variant="contained"
          color="success"
          endIcon={<AddIcon />}
          onClick={() => navigate('/user-create')}
        >
          Создать Менеджера
        </Button>
        <TableContainer component={Paper} sx={{mt: 2}}>
          <Table sx={{minWidth: 200}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Имя</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="h6">Роль</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="h6">Email</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="h6">ID</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="h6">Действия</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersList.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{'&:last-child td, &:last-child th': {border: 0}}}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.role}</TableCell>
                  <TableCell align="right">{row.email}</TableCell>
                  <TableCell align="right">{row.id}</TableCell>
                  <TableCell align="right">
                    <Button
                      variant="outlined"
                      startIcon={<DeleteIcon />}
                      sx={{mr: 1}}
                      color="error"
                      onClick={() => handleClickOpen(row, true)}
                    >
                      Удалить
                    </Button>
                    <Button
                      color="secondary"
                      variant="outlined"
                      startIcon={<EditIcon />}
                      onClick={() => handleClickOpen(row, false)}
                    >
                      Изменить
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <EditUser
        handleClose={handleClose}
        open={open}
        isDeleting={isDeleting}
        setIsDeleting={setIsDeleting}
        editUser={editUser}
        setEditUser={setEditUser}
        setRefresh={setRefresh}
        refresh={refresh}
      />
    </React.Fragment>
  );
}
