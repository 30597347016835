import {
  Box,
  Grid,
  Link,
  TextField,
  CssBaseline,
  Button,
  Avatar,
  Typography,
  Container,
} from '@mui/material';
import Logo from '../../assets/Logo.png';
import {useState} from 'react';
import {authApi} from '../../api/auth';
import useGlobal from '../../store/slice';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import LanguageToggle from '../../components/LanguageToggle';

export default function SignIn() {
  const [userData, setUserData] = useState({login: '', password: ''});
  const [errorLogin, setErrorLogin] = useState(false);
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {setAuth} = useGlobal();

  const helperTextLogin = 'Неверный логин или пароль';

  const handleSubmit = async (event) => {
    event.preventDefault();

    const resp = await authApi.login(userData.login, userData.password);
    if (!resp?.tokens) {
      setErrorLogin(true);
    }
    console.log(resp);

    if (resp.tokens && resp.user) {
      localStorage.setItem('accessToken', resp.tokens.access.token);
      localStorage.setItem('refreshToken', resp.tokens.refresh.token);
      localStorage.setItem('user_name', resp.user.name);
      localStorage.setItem('user_role', resp.user.role);
      localStorage.setItem('user_id', resp.user.id);
      localStorage.setItem('user_email', resp.user.email);

      setAuth(true);
      navigate('/checkout');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box sx={{position: 'absolute', right: 10, top: 1}}>
        <LanguageToggle />
      </Box>

      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box>
          <img src={Logo} alt="" height={50} />
        </Box>

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={t('login')}
            name="email"
            autoFocus
            onChange={(e) =>
              setUserData((prev) => ({...prev, login: e.target.value}))
            }
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={t('password')}
            type="password"
            id="password"
            helperText={errorLogin ? helperTextLogin : ''}
            error={errorLogin}
            onChange={(e) =>
              setUserData((prev) => ({...prev, password: e.target.value}))
            }
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 3, mb: 2}}
          >
            {t('sign_in')}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                {t('forgot_password')}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
