import {createContext, useEffect} from 'react';
import {authApi} from '../api/auth';
import useGlobal from '../store/slice';

export const AuthContext = createContext({});

export const AuthProvider = (props) => {
  const {setUser, setUserToken, auth} = useGlobal();
  const {children} = props;
  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const refreshToken = window.localStorage.getItem('refreshToken');
        const user_name = window.localStorage.getItem('user_name');
        const user_role = window.localStorage.getItem('user_role');
        const user_id = window.localStorage.getItem('user_id');
        const user_email = window.localStorage.getItem('user_email');
        console.log('getting user');
        if (accessToken && user_email && user_id && user_id && user_name) {
          await authApi.me(accessToken, refreshToken);
          setUser({user_email, user_id, user_role, user_name});
          setUserToken(accessToken);
        } else {
          setUser(null);
        }
      } catch (err) {
        console.error(err);
        setUser(null);
      }
    };

    initialize();
  }, [auth]);

  return <AuthContext.Provider value> {children}</AuthContext.Provider>;
};

export const AuthConsumer = AuthContext.Consumer;
