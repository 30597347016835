import Checkout from './content/Checkout/Checkout';
import Home from './content/Home';
import Login from './content/Login';
import {Routes, Route} from 'react-router-dom';
import UserCreate from './content/UserCreate';
import Management from './content/Management';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/auth" element={<Login />}></Route>
        <Route path="/checkout" element={<Checkout />}></Route>
        <Route path="/" element={<Home />}></Route>
        <Route path="/user-create" element={<UserCreate />}></Route>
        <Route path="/management" element={<Management />}></Route>
      </Routes>
    </div>
  );
}

export default App;
