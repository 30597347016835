import {create} from 'zustand';

const initialState = {
  auth: null,
  user: {},
  lang: 'ru',
  token: null,
};

const useGlobal = create((set, get) => ({
  ...initialState,

  setUser: (data) =>
    set((state) => ({
      user: data,
    })),

  setUserToken: (data) =>
    set((state) => ({
      token: data,
    })),

  setAuth: (data) =>
    set((state) => ({
      auth: data,
    })),

  setLang: (language) =>
    set((state) => ({
      lang: language,
    })),
}));

export default useGlobal;
