import * as React from 'react';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Button,
  Container,
  TextField,
  Box,
  Grid,
  InputAdornment,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import {useTranslation} from 'react-i18next';
import styled from '@emotion/styled';

import AppBarGray from '../Home/AppBarGray';

import LanguageToggle from '../../components/LanguageToggle';
import Snackbar from '@mui/material/Snackbar';
import useGlobal from '../../store/slice';
import {useNavigate} from 'react-router-dom';
import {policeApi} from '../../api/police';
import {brands} from '../../assets/brands';

const StyledTextField = styled(TextField)({
  background: '#F5F6F9',
});

export default function Checkout() {
  const [userData, setUserData] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'right',
    message: 'success',
    severity: 'success',
  });

  const [myError, setMyError] = React.useState({
    dateError: false,
    dateErrorBuy: false,
    dateErrorBegin: false,
    dateErrorEnd: false,
    phoneError: false,
    birthdayError: false,
    passNumError: false,
    pinflError: false,
    passSerError: false,
  });

  const {vertical, horizontal, open, message, severity} = state;

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpenModal(true);
  };

  const handleCloseModal = async () => {
    setOpenModal(false);
  };

  const {t} = useTranslation();

  const {user} = useGlobal();
  const navigate = useNavigate();

  const handleNext = (e) => {
    const data = {...userData, phone: userData?.phone.substring(1)};
    console.log(data);
    // policeApi.createPolice(data).then((resp) => {
    //   console.log(resp);
    //   setState({...state, message: 'Страховка создана', open: true});
    // });
  };

  React.useEffect(() => {
    if (!user) {
      navigate('/auth');
    }
  }, [user]);

  const handleClose = () => {
    setState({...state, open: false});
  };

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{vertical, horizontal}}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
        autoHideDuration={6000}
      >
        <Alert onClose={handleClose} severity={severity} sx={{width: '100%'}}>
          {message}
        </Alert>
      </Snackbar>
      <form onSubmit={handleClickOpen}>
        <AppBarGray />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            mt: 1,
            px: 3,
          }}
        >
          <LanguageToggle />
        </Box>
        <Container component="main" maxWidth="lg" sx={{mb: 4, mt: 2}}>
          <Typography
            sx={{fontSize: '44px', fontWeight: 600, py: 4, userSelect: 'none'}}
          >
            {t('polis_ins')}
          </Typography>

          <Typography
            sx={{fontSize: '28px', fontWeight: 500, userSelect: 'none'}}
            gutterBottom
          >
            {t('obj_ins')}
          </Typography>

          <Grid container spacing={3} md={12} sx={{mb: 4}}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="filled">
                <InputLabel id="demo-simple-select-label">
                  {t('brand')}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={t('brand')}
                  value={userData?.texBrand || ' '}
                  onChange={(e) =>
                    setUserData((prev) => ({...prev, texBrand: e.target.value}))
                  }
                >
                  {brands.map((el) => (
                    <MenuItem value={el} key={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                required
                id="model"
                name="model"
                label={t('model')}
                fullWidth
                autoComplete="model"
                variant="outlined"
                onChange={(e) =>
                  setUserData((prev) => ({...prev, texModel: e.target.value}))
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                required
                id="tech-name"
                name="tech-name"
                label={t('name_elec')}
                fullWidth
                autoComplete="tech-name"
                variant="outlined"
                onChange={(e) =>
                  setUserData((prev) => ({...prev, texName: e.target.value}))
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                required
                id="serial"
                name="serial"
                label={t('serial')}
                fullWidth
                autoComplete="serial"
                variant="outlined"
                onChange={(e) =>
                  setUserData((prev) => ({
                    ...prev,
                    texSerialNumber: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                required
                id="purchase-date"
                name="purchase-date"
                label={t('date_purch')}
                fullWidth
                autoComplete="purchase-date"
                variant="outlined"
                placeholder="yyyy-mm-dd"
                inputProps={{maxLength: 10}}
                error={myError?.dateErrorBuy}
                helperText={
                  myError.dateErrorBuy ? 'Введите верный формат yyyy-mm-dd' : ''
                }
                onChange={(e) => {
                  const inputDate = e.target.value;
                  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
                  if (dateRegex.test(inputDate)) {
                    setUserData((prev) => ({...prev, texBuyDate: inputDate}));
                    setMyError((prev) => ({...prev, dateErrorBuy: false}));
                  } else {
                    setMyError((prev) => ({...prev, dateErrorBuy: true}));
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                required
                id="store_adr"
                name="store_adr"
                label={t('store_adr')}
                fullWidth
                autoComplete="store_adr"
                variant="outlined"
                onChange={(e) =>
                  setUserData((prev) => ({...prev, buy_place: e.target.value}))
                }
              />
            </Grid>
          </Grid>

          <Typography
            sx={{fontSize: '28px', fontWeight: 500, mt: 6, userSelect: 'none'}}
            gutterBottom
          >
            {t('begin_ins')}
          </Typography>

          <Grid container spacing={3} md={12}>
            <Grid item xs={12} sm={7}>
              <FormControl fullWidth variant="filled">
                <InputLabel id="demo-simple-select-label">
                  {t('begin_ins')}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={userData?.insurance_time}
                  label={t('begin_ins')}
                  onChange={(e) =>
                    setUserData((prev) => ({
                      ...prev,
                      insurance_time: e.target.value,
                    }))
                  }
                >
                  <MenuItem value={'12'}>12 месяцев</MenuItem>
                  <MenuItem value={'6'}>6 месяцев</MenuItem>
                  <MenuItem value={'3'}>3 месяцев</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                required
                id="begin_polis"
                name="begin_polis"
                label={t('begin_polis')}
                fullWidth
                placeholder="dd-mm-yyyy"
                inputProps={{maxLength: 10}}
                autoComplete="begin_polis"
                variant="outlined"
                error={myError?.dateErrorBegin}
                helperText={
                  myError.dateErrorBegin
                    ? 'Введите верный формат dd-mm-yyyy'
                    : ''
                }
                onChange={(e) => {
                  const inputDate = e.target.value;
                  const dateRegex =
                    /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/;
                  if (dateRegex.test(inputDate)) {
                    setUserData((prev) => ({...prev, begin_polis: inputDate}));
                    setMyError((prev) => ({...prev, dateErrorBegin: false}));
                  } else {
                    setMyError((prev) => ({...prev, dateErrorBegin: true}));
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                required
                id="end_polis"
                name="end_polis"
                label={t('end_polis')}
                placeholder="dd-mm-yyyy"
                inputProps={{maxLength: 10}}
                fullWidth
                autoComplete="end_polis"
                variant="outlined"
                error={myError?.dateErrorEnd}
                helperText={
                  myError.dateErrorEnd ? 'Введите верный формат dd-mm-yyyy' : ''
                }
                onChange={(e) => {
                  const inputDate = e.target.value;
                  const dateRegex =
                    /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/;
                  if (dateRegex.test(inputDate)) {
                    setUserData((prev) => ({...prev, end_polis: inputDate}));
                    setMyError((prev) => ({...prev, dateErrorEnd: false}));
                  } else {
                    setMyError((prev) => ({...prev, dateErrorEnd: true}));
                  }
                }}
              />
            </Grid>
          </Grid>

          <Typography
            sx={{fontSize: '28px', fontWeight: 500, mt: 6, userSelect: 'none'}}
            gutterBottom
          >
            {t('fio')}
          </Typography>

          <Grid container spacing={3} md={12}>
            <Grid item xs={12} sm={4}>
              <StyledTextField
                required
                id="person_info_surname"
                name="person_info_surname"
                label={t('family_name')}
                fullWidth
                autoComplete="person_info_surname"
                variant="outlined"
                onChange={(e) =>
                  setUserData((prev) => ({
                    ...prev,
                    surname: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <StyledTextField
                required
                id="name"
                name="name"
                label={t('name')}
                fullWidth
                autoComplete="model"
                variant="outlined"
                onChange={(e) =>
                  setUserData((prev) => ({...prev, name: e.target.value}))
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <StyledTextField
                required
                id="father_name"
                name="father_name"
                label={t('father_name')}
                fullWidth
                autoComplete="father_name"
                variant="outlined"
                onChange={(e) =>
                  setUserData((prev) => ({...prev, patronymic: e.target.value}))
                }
              />
            </Grid>
          </Grid>

          <Typography
            gutterBottom
            sx={{fontSize: '28px', fontWeight: 500, mt: 6, userSelect: 'none'}}
          >
            {t('fio_data')}
          </Typography>

          <Grid container spacing={3} md={12}>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                required
                id="mobile"
                name="mobile"
                label={t('mobile')}
                fullWidth
                inputProps={{maxLength: 13}}
                autoComplete="mobile"
                placeholder="+998*********"
                variant="outlined"
                error={myError?.phoneError}
                helperText={
                  myError.phoneError
                    ? 'Введите верный формат +998XXXXXXXXX'
                    : ''
                }
                onChange={(e) => {
                  const inputDate = e.target.value;
                  const phoneRegex = /^\+998\d{9}$/;
                  if (phoneRegex.test(inputDate)) {
                    setUserData((prev) => ({...prev, phone: inputDate}));
                    setMyError((prev) => ({...prev, phoneError: false}));
                  } else {
                    setMyError((prev) => ({...prev, phoneError: true}));
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                required
                id="address"
                name="address"
                label={t('address')}
                fullWidth
                autoComplete="address"
                variant="outlined"
                onChange={(e) =>
                  setUserData((prev) => ({
                    ...prev,
                    address: e.target.value,
                  }))
                }
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <StyledTextField
                required
                id="date_birth"
                name="date_birth"
                placeholder="dd-mm-yyyy"
                inputProps={{maxLength: 10}}
                label={t('date_birth')}
                fullWidth
                autoComplete="date_birth"
                variant="outlined"
                error={myError?.birthdayError}
                helperText={
                  myError.birthdayError
                    ? 'Введите верный формат dd-mm-yyyy'
                    : ''
                }
                onChange={(e) => {
                  const inputDate = e.target.value;
                  const dateRegex =
                    /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/;
                  if (dateRegex.test(inputDate)) {
                    setUserData((prev) => ({...prev, birthday: inputDate}));
                    setMyError((prev) => ({...prev, birthdayError: false}));
                  } else {
                    setMyError((prev) => ({...prev, birthdayError: true}));
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <StyledTextField
                required
                id="serial_number"
                name="serial_number"
                label={t('pas_serial')}
                inputProps={{maxLength: 2}}
                fullWidth
                autoComplete="serial_number"
                variant="outlined"
                error={myError?.passSerError}
                helperText={
                  myError.passSerError ? 'Введите верный формат ХХ' : ''
                }
                onChange={(e) => {
                  const inputDate = e.target.value;
                  const dateRegex = /^[A-Za-z]{2}$/;
                  if (dateRegex.test(inputDate)) {
                    setUserData((prev) => ({
                      ...prev,
                      passportSerie: inputDate,
                    }));
                    setMyError((prev) => ({...prev, passSerError: false}));
                  } else {
                    setMyError((prev) => ({...prev, passSerError: true}));
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <StyledTextField
                required
                id="serial_number"
                name="serial_number"
                label={t('pas_number')}
                fullWidth
                inputProps={{maxLength: 7}}
                autoComplete="serial_number"
                variant="outlined"
                error={myError?.passNumError}
                helperText={myError.passNumError ? 'Введите верный формат' : ''}
                onChange={(e) => {
                  const inputDate = e.target.value;
                  const dateRegex = /^\d{7}$/;
                  if (dateRegex.test(inputDate)) {
                    setUserData((prev) => ({
                      ...prev,
                      passportNumber: inputDate,
                    }));
                    setMyError((prev) => ({...prev, passNumError: false}));
                  } else {
                    setMyError((prev) => ({...prev, passNumError: true}));
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <StyledTextField
                required
                id="pnfl"
                name="pnfl"
                label={t('pnfl')}
                inputProps={{maxLength: 14}}
                fullWidth
                autoComplete="pnfl"
                variant="outlined"
                error={myError?.pinflError}
                helperText={myError.pinflError ? 'Введите верный формат' : ''}
                onChange={(e) => {
                  const inputDate = e.target.value;
                  const dateRegex = /^\d{14}$/;
                  if (dateRegex.test(inputDate)) {
                    setUserData((prev) => ({
                      ...prev,
                      pinfl: inputDate,
                    }));
                    setMyError((prev) => ({...prev, pinflError: false}));
                  } else {
                    setMyError((prev) => ({...prev, pinflError: true}));
                  }
                }}
              />
            </Grid>
          </Grid>

          <Typography
            gutterBottom
            sx={{fontSize: '28px', fontWeight: 500, mt: 6, userSelect: 'none'}}
          >
            {t('ins_cover')}
          </Typography>

          <Grid container spacing={3} md={12}>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                required
                id="ins_total"
                name="ins_total"
                label={t('ins_total')}
                fullWidth
                autoComplete="ins_total"
                variant="outlined"
                type="number"
                onChange={(e) =>
                  setUserData((prev) => ({
                    ...prev,
                    insuranceSum: e.target.value,
                  }))
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">сум</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                required
                id="ins_prem"
                name="ins_prem"
                label={t('ins_prem')}
                fullWidth
                autoComplete="ins_prem"
                variant="outlined"
                type="number"
                onChange={(e) =>
                  setUserData((prev) => ({...prev, ins_prem: e.target.value}))
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">сум</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Box sx={{display: 'flex', mt: 1}}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              sx={{mt: 3, borderRadius: '16px', fontWeight: '600'}}
            >
              {t('Отправить')}
            </Button>
          </Box>
        </Container>

        <Dialog open={openModal} onClose={handleCloseModal} maxWidth="xl">
          <DialogTitle
            id="alert-dialog-title"
            sx={{textDecoration: 'underline'}}
          >
            {user?.user_name},проверьте данные
          </DialogTitle>

          <DialogContent
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 2,
              flexDirection: 'column',
              minWidth: 700,
            }}
          >
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="h7">{t('brand')}</Typography>
              <Typography fontWeight={400}> {userData?.texBrand}</Typography>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="h7"> {t('model')}</Typography>
              <Typography fontWeight={400}> {userData?.texModel}</Typography>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="h7">{t('name_elec')}</Typography>
              <Typography fontWeight={400}> {userData?.texName}</Typography>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="h7">{t('serial')}</Typography>
              <Typography fontWeight={400}>
                {userData?.texSerialNumber}
              </Typography>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="h7"> {t('date_purch')}</Typography>
              <Typography fontWeight={400}>{userData?.texBuyDate}</Typography>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="h7"> {t('store_adr')} </Typography>
              <Typography fontWeight={400}>{userData?.buy_place}</Typography>
            </Box>

            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="h7"> {t('begin_polis')} </Typography>
              <Typography fontWeight={400}>{userData?.begin_polis}</Typography>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="h7"> {t('end_polis')} </Typography>
              <Typography fontWeight={400}>{userData?.end_polis}</Typography>
            </Box>

            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="h7"> {t('family_name')} </Typography>
              <Typography fontWeight={400}>{userData?.surname}</Typography>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="h7"> {t('name')} </Typography>
              <Typography fontWeight={400}>{userData?.name}</Typography>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="h7"> {t('father_name')} </Typography>
              <Typography fontWeight={400}> {userData?.patronymic}</Typography>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="h7"> {t('mobile')}</Typography>
              <Typography fontWeight={400}> {userData?.phone}</Typography>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="h7"> {t('address')}</Typography>
              <Typography fontWeight={400}> {userData?.address}</Typography>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="h7"> {t('date_birth')}</Typography>
              <Typography fontWeight={400}> {userData?.birthday}</Typography>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="h7"> {t('pas_serial')}</Typography>
              <Typography fontWeight={400}>
                {userData?.passportSerie}
              </Typography>
            </Box>

            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="h7"> {t('pas_number')}</Typography>
              <Typography fontWeight={400}>
                {userData?.passportNumber}
              </Typography>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="h7"> {t('pnfl')}</Typography>
              <Typography fontWeight={400}>{userData?.pinfl}</Typography>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="h7"> {t('ins_total')}</Typography>
              <Typography fontWeight={400}>{userData?.insuranceSum}</Typography>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="h7"> {t('ins_prem')}</Typography>
              <Typography fontWeight={400}> {userData?.ins_prem}</Typography>
            </Box>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseModal} variant="outlined" color="error">
              Отмена
            </Button>
            <Button onClick={handleNext} variant="contained" s>
              Сохранить
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </React.Fragment>
  );
}
