import {decode} from '../utils/jwt';

const APIHOST = 'https://dev.itconsult.uz/api/v1';

function getLocalToken() {
  return window.localStorage.getItem('accessToken');
}

function getLocalRefreshToken() {
  return window.localStorage.getItem('refreshToken');
}

function handleErrors(response) {
  if (!response.ok) {
    throw response;
  }
  return response;
}

async function getToken(authData) {
  return fetch(APIHOST + '/auth/login', {
    method: 'POST',
    body: JSON.stringify(authData),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(handleErrors)
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      return {
        message: 'Invalid credentials.',
        error: err,
      };
    });
}

async function getRefreshToken() {
  return fetch(APIHOST + '/auth/refresh-tokens', {
    method: 'POST',
    body: JSON.stringify({refreshToken: getLocalRefreshToken()}),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(handleErrors)
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      return {
        message: 'Invalid credentials.',
        error: err,
      };
    });
}

class AuthApi {
  async login(email, password) {
    return new Promise((resolve, reject) => {
      try {
        getToken({email: email, password: password}).then((resp) => {
          resolve(resp);
        });
      } catch (err) {
        console.error(err);
        reject(new Error('Internal server error'));
      }
    });
  }

  me(accessToken, refreshToken) {
    return new Promise((resolve, reject) => {
      try {
        const decodedToken = decode(accessToken);

        if (decodedToken.exp < new Date().getTime() / 1000) {
          getRefreshToken().then((resp) => {
            console.log(resp, 'IM RSP');
            localStorage.setItem('accessToken', resp.access.token);
            localStorage.setItem('refreshToken', resp.refresh.token);
            resolve();
          });
          // reject(new Error('Authorization token is expired.'));
          return;
        } else {
          resolve();
        }
      } catch (err) {
        console.error(err);
        reject(new Error('Internal server error'));
      }
    });
  }
}

export const authApi = new AuthApi();
