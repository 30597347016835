import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';

import Logo from '../../assets/Logo.png';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import useGlobal from '../../store/slice';
import {Logout} from '@mui/icons-material';
import LanguageToggle from '../../components/LanguageToggle';
import {useTranslation} from 'react-i18next';

function Header(props) {
  const navigate = useNavigate();
  const {user, setUser} = useGlobal();
  const {t} = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setUser(null);
    setAnchorEl(null);
    localStorage.clear();
    navigate('/auth');
  };

  return (
    <React.Fragment>
      <Toolbar
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'space-between',
          mt: 1,
        }}
      >
        <Box>
          <img src={Logo} alt="" height={50} />
        </Box>

        {!user && (
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => navigate('/auth')}
              sx={{mr: 5}}
            >
              {t('sign_in')}
            </Button>
            <LanguageToggle />
          </Box>
        )}
        {user && (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <Typography
                onClick={() => navigate('/contacts')}
                sx={{
                  p: 1,
                  flexShrink: 0,
                  minWidth: 100,
                  cursor: 'pointer',
                  '&:hover': {color: '#0136FF'},
                }}
              >
                {t('contacts')}
              </Typography>
              <Typography
                onClick={() => navigate('/checkout')}
                sx={{
                  p: 1,
                  flexShrink: 0,
                  minWidth: 100,
                  cursor: 'pointer',
                  '&:hover': {color: '#0136FF'},
                }}
              >
                {t('create_ins')}
              </Typography>
              {user?.user_role === 'admin' && (
                <Typography
                  onClick={() => navigate('/management')}
                  sx={{
                    p: 1,
                    flexShrink: 0,
                    minWidth: 100,
                    cursor: 'pointer',
                    '&:hover': {color: '#0136FF'},
                  }}
                >
                  {t('Менеджмент')}
                </Typography>
              )}
              <Tooltip title={t('profile_settings')}>
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ml: 2}}
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  <Avatar sx={{width: 32, height: 32}}>AR</Avatar>
                </IconButton>
              </Tooltip>
              <LanguageToggle />
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              transformOrigin={{horizontal: 'right', vertical: 'top'}}
              anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
              <MenuItem onClick={handleClose}>{t('profile')}</MenuItem>
              <Divider />
              {/* <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem> */}
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                {t('sign_out')}
              </MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </React.Fragment>
  );
}

export default Header;
