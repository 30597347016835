import axios from 'axios';

const APIHOST = 'https://dev.itconsult.uz/api/v1';

function getLocalToken() {
  const token = window.localStorage.getItem('accessToken');
  return `Bearer ${token}`;
}

class UsersApi {
  getAllUsers() {
    return axios.get(`${APIHOST}/users`, {
      headers: {
        Authorization: getLocalToken(),
      },
    });
  }

  createUser(data) {
    return axios.post(
      `${APIHOST}/users`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: getLocalToken(),
        },
      }
    );
  }

  editUser(data) {
    return axios.post(
      `${APIHOST}/users/${data.id}`,
      {
        name: data.name,
        email: data.email,
        password: data.password,
      },
      {
        headers: {
          Authorization: getLocalToken(),
        },
      }
    );
  }

  deleteUser(id) {
    return axios.delete(`${APIHOST}/users/${id}`, {
      headers: {
        Authorization: getLocalToken(),
      },
    });
  }
}

export const usersApi = new UsersApi();
